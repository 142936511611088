import Modal from "../Modal/Modal";
import React, { useState } from "react";
import styles from "./ModalUpdates.module.sass";

const ModalUpdates = (props) => {
  const {licenses, versions} = props
  const [selectedLicense, setSelectedLicense] = useState(licenses.active[0])

  const iframHeight = () => {
    return licenses.active.length == 1 ? {height: "99%"} : {}
  }

  return (

    <Modal {...props} background={true} navbar={true}>
      <div className={styles.modalUpdates}>
        { licenses.active.length > 1 &&
          <div className={styles.licenseTabs}>
            <div className={`row`} style={{padding: "0px 12px"}}>
              {licenses.active.map( (license, index) => {
                return (
                  <div className={`col ${styles.licenseTab}`} 
                      key={license}
                      onClick={() => setSelectedLicense(license)}>
                    {licenses.data[`${license}`].name}
                    {index < licenses.active.length - 1 && 
                      <span className={styles.separator}></span>
                    }
                    {selectedLicense == license &&
                      <span className={styles.active} style={{borderColor: `${licenses.data[license].color}`}}></span>
                    }
                  </div>
                )
              })}
            </div>
            <div className={styles.bottomSeparator}></div> 
          </div>
        }
        <iframe src={versions.euleriaLab} className={selectedLicense == "euleria_lab" ? "" : "d-none"} style={iframHeight()}></iframe>
        <iframe src={versions.xClinic} className={selectedLicense == "xclinic" ? "" : "d-none"} style={iframHeight()}></iframe>
        <iframe src={versions.euleriaGym} className={selectedLicense == "euleria_gym" ? "" : "d-none"} style={iframHeight()}></iframe>
        <iframe src={versions.euleriaHome} className={selectedLicense == "euleria_home" ? "" : "d-none"} style={iframHeight()}></iframe>
      </div>
    </Modal>
  );
};

export { ModalUpdates as default };
