import React from "react";
import style from "./ProfessionalBox.module.sass";
import ProfessionalMenu from "./ProfessionalMenu/ProfessionalMenu";
import { useState } from "react";
function ProfessionalBox(props) {
  const {
    actions,
    activeActions,
    actionsUrls,
    archiveRedirect,
    archiveTranslations,
    assignTranslations,
    assignModal,
    linkToClick,
    professionalEmail,
    patientIdTooltip,
    fullName,
  } = props;

  const renderFullName = () => {
    return (
      <a className={`d-flex ${style.fullName}`}>
        <span style={{ paddingTop: "5px" }}>{fullName}</span>
        {renderEmail()}
      </a>
    );
  };

  const renderEmail = () => {
    return (
      <div className={`auto-tooltip d-flex ${style.patientId}`}
        data-toggle="tooltip"
        title={patientIdTooltip}>
        <div className={`patient-email ps-1`}>{professionalEmail}</div>
      </div>

    );
  };

  const goToPatients = () => {
    window.location.href = linkToClick;
  };

  return (
    <div className={`${style.ProfessionalBox} clickable`}
      onClick={() => goToPatients()}>
      <div className={`col ${style.flexContainer}`}>
        <div className={style.containerRow}>
          <div>
            {renderFullName()}
          </div>
        </div>
      </div>
      <div className={style.ProfessionalMenuCol}>
        {activeActions.length > 0 &&
          <ProfessionalMenu
            actionsUrls={actionsUrls}
            activeActions={activeActions}
            archiveRedirect={archiveRedirect}
            archiveTranslations={archiveTranslations}
            assignTranslations={assignTranslations}
            assignModal={assignModal}
            professionalName={fullName}
            translations={actions}
          />
        }
      </div>
    </div>
  );
}

ProfessionalBox.defaultProps = {};

export default ProfessionalBox;
