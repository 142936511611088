import React, { useState, useEffect, useRef } from "react";
import ExerciseSequenceChartEffort from '../ExerciseSequence/ExerciseSequenceChart/ExerciseSequenceChartEffort'
import ExerciseSequenceChartPulseOximeter from '../ExerciseSequence/ExerciseSequenceChart/ExerciseSequenceChartPulseOximeter'
import ExerciseResult from "../ExerciseResult/ExerciseResult";
import ReactsExerciseResult from "../ReactsExerciseResult/ReactsExerciseResult";
import SliderDetails from "./SliderDetails";
import classes from "./Slider.module.sass";
import ExerciseFooterDownload from "../ExerciseFooterDownload/ExerciseFooterDownload";
import ExerciseFooterCSVDownload from "../ExerciseFooterCSVDownload/ExerciseFooterCSVDownload";
import { callApi } from "../../../helpers";
import XclinicEvaluationResult from "./XclinicEvaluationResult/XclinicEvaluationResult";
import EuleriagymEvaluationResult from "./EuleriagymEvaluationResult/EuleriagymEvaluationResult";
import SliderStatus from "./SliderStatus";
import OutdoorResultsChart from "../OutdoorResultsChart/OutdoorResultsChart";
import ProgressBar from "../ProgressBar/ProgressBar"
import StrengthResult from "../../StrengthResult/StrengthResult";
import StrengthGeneralResult from "../../StrengthGeneralResult/StrengthGeneralResult";
import UpperLimbResult from "../UpperLimbResult/UpperLimbResult";
import UpperLimbGeneralResult from "../UpperLimbGeneralResult/UpperLimbGeneralResult";
function Slider(props) {
  const {
    colors,
    duration,
    footer,
    id,
    initModal,
    isEuleriaLab,
    isMedicair,
    isOutdoor,
    isOpen,
    leftShortText,
    medicairData,
    movements,
    notes,
    outdoorData,
    path,
    repetitions,
    rightShortText,
    times,
    type,
    downloadCSV,
    license,
    r2s_upper_limb,
    injured_side
  } = props;

  // close, loading, open, error
  const [error, setError] = useState(null);
  const [data, setData] = useState(null);
  const [statusSlider, setStatusSlider] = useState("close");

  const slider = useRef()

  const isLabResultWithModal = isEuleriaLab && type != "strength_general_result"

  useEffect(() => {
    if (isOpen) {
      toggleDetails()
    }
  }, [isOpen])

  async function submitForm() {
    if (movements == null && !isOutdoor) {
      return callApi(path, "GET", "json", (data) => {
        setData(data);
        setStatusSlider("open");
      });
    } else {
      setStatusSlider("open");
    }
  }

  async function toggleDetails() {
    if (isLabResultWithModal) {
      const uniqueUrl = `${path}&rand=${Math.random().toString(36).substring(2, 15)}`;
      initModal(uniqueUrl, '');
      slider.current.click()
      return;
    }

    if (statusSlider == "close") {
      setStatusSlider("loading");
      try {
        await submitForm();
      } catch (err) {
        setStatusSlider("error");
        setError(err);
      }
    } else {
      setStatusSlider("close");
      setData(null);
    }

  }

  const currentCssClass = () => {
    if (statusSlider == "open") {
      return `${classes.bgGradient} ${classes.sliderOpen}`;
    }
    return `${classes.bgGradient} ${classes.sliderClose}`;
  };

  const renderExercisesResults = () => {
    if (type == "cardio_daily_sequence") {
      return data?.exercises_results.map((el) => {
        return (
          <ReactsExerciseResult
            {...el}
            colors={colors}
            key={el.index}
          />
        );
      });
    } else if (type == "strength_general_result") {
      return data?.exercises_results.map((el) => {
        return (
          <StrengthResult
            {...el}
            key={el.index}
            color={colors.primaryColor}
            license={license}
          />
        )
      })
    }  else if (type == "kari_connect_daily_sequence") {
      return data?.exercises_results.map((el) => {
        return (
          <ExerciseResult
            {...el}
            colors={colors}
            key={el.index}
            leftShortText={leftShortText}
            rightShortText={rightShortText}
            type={type}
          />
        )
      })
    } else if (r2s_upper_limb) {
      return data?.exercises_results.map((el) => {
        return (
          <UpperLimbResult
            {...el}
            key={el.index}
            color={colors.primaryColor}
            license={props.license}
          />
        )
      })
    } else {
      return data?.exercises_results.map((el) => {
        return (
          <ExerciseResult
            {...el}
            colors={colors}
            key={el.index}
            leftShortText={leftShortText}
            rightShortText={rightShortText}
            type={type}
          />
        );
      });
    }
  };

  const renderExerciseResultsMedicair = () => {
    return data.exercises_results.map((el) => {
      const effortData = data?.exercises_results[0].dataForEffortChart[0].results[0].samples[0]
      return (
        <div>
          {effortData.length != 0 && <ExerciseSequenceChartEffort
            title={medicairData.chartTitleEffort}
            effortData={effortData}
          />}
          <ExerciseSequenceChartPulseOximeter
            heartRateData={data?.exercises_results[0].dataForPulseOximeterChart[0].results[0].samples[0]}
            heartRateLabel={data?.exercises_results[0].dataForPulseOximeterChart[0].title}
            medicairData={medicairData}
            saturationData={data?.exercises_results[0].dataForPulseOximeterChart[1].results[0].samples[0]}
            saturationLabel={data?.exercises_results[0].dataForPulseOximeterChart[1].title}
            title={medicairData.chartTitlePulseOximeter}
          />
        </div>
      );
    });
  }

  const renderXclinicEvaluationResults = () => {
    return movements.map((movement, index) => {
      return (
        <XclinicEvaluationResult
          key={`${id}-${index}`}
          {...movement}
          id={`${id}-${index}`}
        />
      )
    })
  }

  const renderEuleriagymEvaluationResults = () => {
    return movements.map((movement, index) => {
      return (
        <EuleriagymEvaluationResult
          key={`${id}-${index}`}
          {...movement}
          id={`${id}-${index}`}
        />
      )
    })
  }

  const renderOutdoorSessionResults = () => {
    return outdoorData.results.map((result, index) => {
      return (
        <div key={index}>
          <div className="pt-4 pb-3" style={{ paddingLeft: "20px" }}>
            {result.pain > 1 &&
              <div className="d-flex justify-content-center align-items-center">
                <SliderStatus
                  hasPain={result.pain > 1}
                />
                <div className={classes.outdoorResultPain}>
                  SEGNALATO DOLORE {result.pain}
                  <span className={classes.painScale}>/10</span>
                </div>
              </div>
            }
          </div>
          <div className="d-flex justify-content-center pb-4">
            <div className="w-100">
              <OutdoorResultsChart
                colors={colors}
                duration={result.duration}
                hasHeartRateData={result.hasHeartRateData}
                heartRateData={result.heartRateData}
                maxHeartRate={outdoorData.heartRateRange.max}
                minHeartRate={outdoorData.heartRateRange.min}
                alertHeartRate={outdoorData.heartRateRange.emergency}
                workRanges={result.workRanges}
                thresholdLabels={result.thresholdLabels}
                title={result.title}
                noFc={result.noFc}
              />
            </div>
          </div>
        </div>
      )
    })
  }

  const renderDuration = (time) => {
    if (time < 30) {
      return `${time} ${duration.secLabel}`
    }
    return `${Math.round((time || 0) / 60)} ${duration.minLabel}`
  }

  return (
    <div data-toggle={isLabResultWithModal ? 'modal' : undefined}
      data-target={isLabResultWithModal ? '#EuleriaLabActivitiesModal' : undefined}
      data-bs-toggle={isLabResultWithModal ? 'modal' : undefined}
      data-bs-target={isLabResultWithModal ? '#EuleriaLabActivitiesModal' : undefined} >
      <div className={currentCssClass()}
        onClick={toggleDetails}
        style={{ cursor: "pointer" }}
        ref={slider}>
        <SliderDetails
          {...props}
          colors={colors}
          statusSlider={statusSlider}
          isMedicair={isMedicair}
          isOutdoor={isOutdoor}
          medicairData={medicairData}
          isEuleriaLab={isEuleriaLab}
          downloadCSV={downloadCSV}
        />
      </div>
      {statusSlider == "open" &&
        <div>
          {duration && type != 'cardio_daily_sequence' &&
            <div className={classes.duration}>
              <span className={classes.label}>{duration.realDurationLabel} </span>
              <span className={classes.value} style={{ color: colors.primaryColor }}>{renderDuration(duration.realDuration)} </span>
              <span>{duration.separator} </span>
              <span className={classes.value} style={{ color: colors.primaryColor }}>{renderDuration(duration.estimate)} </span>
              <span className={classes.label}>{duration.estimateLabel}</span>
            </div>
          }

          {movements && type=="xclinic_evaluation" &&
            <div className={classes.xclinicEvaluationResults}>
              {renderXclinicEvaluationResults()}
            </div>
          }
          {movements && type=="euleriagym_evaluation" &&
            <div className={classes.euleriagymEvaluationResults}>
              {renderXclinicEvaluationResults()}
            </div>
          }
          {outdoorData &&
            <div>
              <div className={classes.bgGradient} style={{ borderRadius: "0px" }}>
                {renderOutdoorSessionResults()}
                <div className={classes.notes} style={{ color: colors.primaryColor }}>
                  <div className={classes.titleOutdoor}>{outdoorData.note.title}</div>
                  <div className={classes.body}>
                    {outdoorData.note.body}
                  </div>
                </div>
              </div>
            </div>
          }
          {type == "strength_general_result" &&
            <StrengthGeneralResult
              colors={colors}
              id={id}
              notes={notes}
              repetitions={repetitions}
              times={times}
              type={type}
              license={license}
            />
          }
          {r2s_upper_limb &&
            <UpperLimbGeneralResult
            injured_side={injured_side}
            />
          }
          {data?.exercises_results && !outdoorData && (isMedicair && renderExerciseResultsMedicair() || renderExercisesResults())}
          {data?.footer && !downloadCSV && <ExerciseFooterDownload {...data?.footer} colors={colors} />}
          {data?.footer  && r2s_upper_limb && <ExerciseFooterDownload {...data?.footer} colors={colors} />}
          {downloadCSV && !r2s_upper_limb && <ExerciseFooterCSVDownload {...data?.footer} colors={colors} />}
          {(movements || type == 'cardio_daily_sequence' || outdoorData) && <ExerciseFooterDownload {...footer} colors={colors} />}
        </div>
      }

    </div>
  );
}

export default Slider;
