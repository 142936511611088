import React, { useState } from "react";
import style from "./Patients.module.sass";
import PatientListHeader from "./PatientListHeader/PatientListHeader";
import PatientBox from "../PatientBox/PatientBox";
import List from "../List/List";
import FirstAccess from "../FirstAccess/FirstAccess";


function Patients(props) {
  const {
    assignModal,
    createPatient,
    firstAccess,
    supervisor,
    licenses,
    searchSelectOptions,
    searchPatientsPlaceholderText,
    supervisorMode } = props;

  const baseUrl = "/patients";
  const [queryUrl, setQueryUrl] = useState(
    baseUrl + "?name=&order_by=last_sequence_date&direction=desc"
  );

  const onChangeFilter = (newOrder, search, direction) => {
    const params = new URLSearchParams();

    // Add parameters dynamically based on their presence
    if (search) params.set("name", search);
    if (newOrder) params.set("order_by", newOrder);
    if (direction) params.set("direction", direction);
    if (supervisor?.selectedProfessional.id) params.set("professional_id", supervisor.selectedProfessional.id);

    const newQueryUrl = `${baseUrl}?${params.toString()}`;
    setQueryUrl(newQueryUrl);
  };

  const onChangeProfessionalFilter = (professionalId = "") => {
    let newQueryUrl =
      baseUrl +
      "?professional_id=" +
      professionalId
    setQueryUrl(newQueryUrl);
  };

  const archiveReload = () => {
    setQueryUrl(queryUrl + " ")
  }

  const renderPatientItem = (item) => {
    return <PatientBox
      {...item}
      licenses={licenses}
      archiveRedirect={archiveReload}
      assignModal={assignModal}
    />;
  };

  return (
    <div className={`${style.PatientsList}`}>
      <div className={"row mb-4"}>
        <PatientListHeader
          createPatient={createPatient}
          onChangeFilter={onChangeFilter}
          onChangeProfessionalFilter={onChangeProfessionalFilter}
          searchSelectOptions={searchSelectOptions}
          searchPatientsPlaceholderText={searchPatientsPlaceholderText}
          supervisorMode={supervisorMode}
          supervisor={supervisor}
        />
      </div>
      {firstAccess.check ? (
        <FirstAccess {...firstAccess} />
      ) : (
        <div className={style.List}>
          <List baseQuery={queryUrl} renderItem={renderPatientItem} />
        </div>
      )}
    </div>
  );
}

export default Patients;
