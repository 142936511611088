import React, { useState,useEffect } from "react";
import style from "./VrtSession.module.sass";

const ToggleButtons = ({ title, initialStatus = true, onToggle, name }) => {
  const [statuses, setStatuses] = useState({ [name]: initialStatus });
  useEffect(() => {
    onToggle(name,true); //se tdefault true
  }, []);

  const handleToggle = (e, newStatus) => {
    e.preventDefault();
    setStatuses((prevStatuses) => ({
      ...prevStatuses,
      [name]: newStatus,
    }));
    if (onToggle) {
      onToggle(name,newStatus);
    }
  };

  return (
    <div className={style.formField}>
      <div className={`${style.left} ${style.labelField}`}>{title}</div>
      <div className={style.right}>
        <div className={style.toggleButtons}>
          <button
            name={name}
            value={true}
            className={`${style.select_button} ${statuses[name] === true ? style.selected : ""}`}
            onClick={(e) => handleToggle(e, true)}
          >
            On
          </button>
          <button
            name={name}
            className={`${style.select_button} ${statuses[name] === false ? style.selected : ""}`}
            value={false}
            onClick={(e) => handleToggle(e, false)}
          >
            Off
          </button>
        </div>
      </div>
    </div>
  );
};

export default ToggleButtons;
