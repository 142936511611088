import React from "react";

const SessionVRTHome = (props) => {
  return (


<svg version="1.0" xmlns="http://www.w3.org/2000/svg"
 width="50" height="50" viewBox="0 0 900.000000 867.000000"
 preserveAspectRatio="xMidYMid meet">
<metadata>
Created by potrace 1.10, written by Peter Selinger 2001-2011
</metadata>
<g transform="translate(0.000000,867.000000) scale(0.100000,-0.100000)"
fill="#ffffff" stroke="none">
<path d="M4515 8661 c8 -14 -19 -19 -145 -26 -220 -12 -450 -66 -764 -182 -40 -14 -198 -87 -226 -103 -8 -5 -35 -19 -60 -31 -107 -54 -305 -180 -440 -282
-196 -147 -440 -398 -571 -588 -35 -52 -66 -98 -69 -104 -3 -5 -16 -28 -30
-50 -14 -23 -30 -50 -37 -60 -16 -27 -85 -167 -115 -235 -22 -49 -35 -81 -69
-168 -16 -41 -45 -135 -63 -202 -10 -36 -24 -88 -32 -116 -8 -28 -14 -59 -14
-68 0 -10 -6 -48 -14 -85 -34 -157 -49 -358 -43 -611 2 -135 10 -283 16 -330
30 -218 55 -359 83 -465 6 -22 15 -58 19 -80 10 -54 29 -122 44 -165 7 -19 27
-80 44 -135 34 -106 41 -124 91 -250 31 -79 37 -93 80 -190 73 -163 214 -434
290 -555 5 -8 21 -35 36 -60 100 -166 265 -398 409 -574 28 -33 60 -73 71 -88
100 -130 597 -632 719 -728 18 -14 65 -52 105 -85 76 -63 80 -66 237 -183 145
-108 263 -192 267 -192 3 0 33 -19 68 -43 77 -52 131 -77 164 -77 26 0 90 29
139 64 16 11 46 29 65 40 19 10 51 31 71 47 20 16 40 29 43 29 13 0 328 242
434 333 147 125 364 339 486 477 55 63 103 117 106 120 92 91 370 475 490 676
19 33 40 68 47 79 7 11 15 27 19 35 4 8 19 33 33 55 22 36 63 110 111 205 60
116 200 428 220 490 5 14 18 50 30 80 11 30 36 102 55 160 19 58 39 119 45
137 10 29 17 56 57 203 8 30 18 75 22 100 5 25 13 65 19 90 32 134 42 188 42
216 0 18 6 65 14 105 46 240 45 811 -3 1099 -12 70 -19 104 -51 235 -7 28 -16
66 -21 85 -19 81 -77 255 -106 320 -9 19 -21 49 -28 65 -27 64 -120 246 -160
313 -135 225 -368 489 -585 663 -94 76 -265 199 -276 199 -4 0 -25 13 -48 28
-22 15 -99 56 -171 91 -71 35 -143 70 -158 77 -16 8 -36 14 -46 14 -9 0 -25 6
-36 13 -11 7 -47 21 -80 31 -244 75 -330 92 -525 107 -148 11 -164 14 -155 29
4 6 -23 10 -75 10 -47 0 -79 -4 -75 -9z m33 -1313 c69 -64 65 -61 293 -278
101 -96 224 -213 274 -260 103 -97 197 -186 270 -255 28 -27 84 -80 125 -119
41 -38 148 -140 238 -226 90 -85 188 -178 218 -206 30 -27 54 -59 54 -70 0
-13 -18 -28 -63 -50 -34 -17 -106 -54 -159 -82 -54 -29 -106 -52 -117 -52 -12
0 -239 218 -611 586 -587 583 -591 586 -612 568 -11 -11 -275 -274 -586 -586
-311 -313 -573 -568 -582 -568 -10 0 -39 12 -66 26 -27 14 -98 51 -157 81 -59
31 -112 63 -117 73 -8 15 -1 25 33 56 62 55 196 181 332 313 67 64 155 148
196 186 157 146 747 705 853 807 61 59 115 108 119 108 5 0 34 -24 65 -52z
m469 -1290 l523 -523 0 -523 0 -522 -382 2 -383 3 -5 360 -5 360 -285 0 -285
0 -5 -360 -5 -360 -369 -3 c-204 -1 -374 2 -378 6 -7 7 -10 392 -8 1017 0 20
1035 1065 1055 1065 5 0 245 -235 532 -522z"/>
<path d="M979 3144 c-16 -5 -25 -29 -77 -199 -8 -27 -20 -66 -27 -85 -7 -19
-20 -62 -30 -95 -37 -126 -48 -161 -61 -193 -8 -18 -14 -43 -14 -55 -1 -12 -7
-33 -15 -47 -8 -14 -14 -34 -15 -45 0 -11 -7 -33 -15 -49 -8 -15 -15 -37 -15
-47 0 -10 -6 -36 -14 -56 -8 -21 -28 -81 -44 -133 -16 -52 -44 -142 -62 -200
-18 -58 -45 -145 -61 -195 -15 -49 -42 -137 -60 -195 -18 -58 -40 -130 -49
-160 -10 -30 -30 -95 -45 -145 -15 -49 -33 -105 -41 -123 -8 -18 -14 -41 -14
-52 0 -11 -6 -34 -14 -52 -7 -18 -21 -58 -30 -88 -8 -30 -29 -98 -46 -150 -16
-52 -37 -120 -46 -150 -9 -30 -29 -95 -45 -145 -34 -105 -50 -158 -74 -240
-10 -33 -22 -71 -27 -85 -14 -34 -38 -128 -38 -145 0 -13 574 -15 4500 -15
l4500 0 0 25 c0 15 -6 25 -15 25 -8 0 -17 12 -21 27 -3 16 -11 44 -18 63 -7
19 -18 51 -25 70 -6 19 -36 107 -66 195 -29 88 -59 174 -64 190 -30 83 -63
183 -71 210 -4 17 -14 46 -21 65 -6 19 -17 51 -24 70 -7 19 -18 51 -24 70 -7
19 -16 49 -21 65 -8 29 -18 60 -45 135 -7 19 -22 64 -34 100 -11 36 -44 130
-72 210 -28 80 -54 159 -59 175 -4 17 -14 46 -21 65 -6 19 -17 51 -24 70 -7
19 -18 51 -24 70 -7 19 -17 49 -21 65 -9 31 -53 163 -75 225 -16 46 -24 69
-105 310 -38 113 -74 219 -80 235 -34 98 -63 184 -70 210 -11 39 -29 92 -46
133 -8 18 -14 39 -14 47 0 8 -10 17 -23 20 -12 3 -207 5 -432 3 -401 -3 -411
-3 -451 -25 -96 -51 -144 -129 -144 -232 0 -116 51 -198 148 -241 43 -19 65
-21 269 -20 210 0 224 -1 243 -20 11 -11 22 -32 25 -47 4 -16 10 -38 16 -51 7
-19 38 -108 160 -472 7 -19 17 -51 24 -70 21 -60 34 -100 86 -260 28 -85 57
-171 64 -190 26 -74 37 -106 45 -135 5 -16 32 -95 60 -175 28 -80 55 -158 60
-175 5 -16 25 -77 45 -135 21 -58 41 -118 45 -135 5 -16 23 -70 40 -119 17
-49 29 -95 26 -103 -5 -11 -647 -13 -3765 -13 -2711 0 -3762 3 -3767 11 -3 6
8 57 26 112 17 56 38 120 45 142 7 22 20 67 30 100 10 33 23 76 30 95 12 34
22 69 60 195 10 33 23 76 30 95 7 19 20 62 30 95 22 76 38 127 75 245 17 52
38 120 46 150 9 30 22 71 29 90 7 19 20 60 29 90 48 161 78 258 87 285 6 17
16 50 23 75 77 263 94 307 120 314 11 3 139 6 284 6 277 0 279 0 348 50 38 27
83 101 95 154 26 116 -30 228 -143 288 -43 23 -43 23 -503 25 -253 0 -467 -1
-476 -3z"/>
</g>
</svg>

  );
};

export default SessionVRTHome;

