import React from "react"
import { useEffect, useState } from 'react'
import style from "./ProfessionalForm.module.sass"
import CommonFields from "./CommonFields"
import Spinner from "./Spinner"

function ProfessionalForm(props) {
  const {
    action,
    backPath,
    licenses,
    professional,
    submitUrl,
    translations } = props

  const [buttonClicked, setButtonClicked] = useState('')
  const [errors, setErrors] = useState({})
  const fieldsTranslations = translations.fields

  const goBack = () => {
    setButtonClicked('back')
    window.location.href = backPath
  }

  const buttonClass = () => {
    if (Object.keys(errors).length) {
      return `${style.buttonSubmit} ${style.buttonSubmitWithErrors}`
    }
    return style.buttonSubmit
  }

  const submitForm = async (e) => {
    e.preventDefault()
    setButtonClicked('submit')
    const formData = new FormData()
    const params = Array.from(e.target)
      .filter(el => el.name)
      .reduce((a, b) => ({ ...a, [b.name]: b.value }), {})
    formData.professional = params
    let response = await fetch(submitUrl, {
      method: action,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(formData)
    })
    const data = await response.json()

    if (response.ok) {
      window.location.href = data.location
    } else {
      setErrors({ ...data.errors })
    }
    setButtonClicked('')
  }

  const buttonSend = () => {
    if (buttonClicked == 'submit') {
      return <a className={buttonClass()}
        disabled>
        <Spinner />
      </a>
    } else {
      return <input className={buttonClass()}
        type='submit'
        value={translations.button}
        onClick={() => submitForm()} />
    }
  }

  const backLink = () => {
    if (buttonClicked == 'back') {
      return <a className={style.back}
        disabled>
        <Spinner />
      </a>
    } else if (buttonClicked != '') {
      return <a href='javascript:void(0)' className={style.backDisabled}>{translations.back}</a>
    } else {
      return <a href={backPath}
        className={style.back}
        onClick={() => goBack()}>
        {translations.back}
      </a>
    }
  }

  const inputErrorClass = (inputName) => {
    if (errors != undefined && errors[inputName] != undefined) {
      return style.inputWithError
    }
    return ''
  }

  const toggleFieldLabel = (e) => {
    let label = document.getElementById(`${e.target.id}-label`)
    if (e.target.value == "") {
      label.style.display = "none"
    } else {
      label.style.display = "inline"
    }
  }

  const fieldLabelStyle = (value) => {
    if (value == null || value == "") {
      return { display: 'none', position: 'relative' }
    } else {
      return { display: 'inline', position: 'relative' }
    }
  }

  return (
    <div className={`${style.professionalFormWrapper}`}>
      <div className={`${style.title}`}>{translations.title}</div>
      <div className={`${style.subtitle}`}
        style={{ marginBottom: '30px' }}>
        {translations.subtitle}
      </div>
      <form onSubmit={(e) => submitForm(e)}>
        <CommonFields errors={errors}
          fieldsTranslations={fieldsTranslations}
          fieldLabelStyle={fieldLabelStyle}
          professional={professional}
          toggleFieldLabel={toggleFieldLabel}
          inputErrorClass={inputErrorClass} />
        <div className={`${style.legend}`}>{translations.requiredField}</div>
        <div style={{ marginBottom: '20px', marginLeft: '15px' }}>
          {backLink()}
          <span className={`${style.buttonRight}`}>
            {buttonSend()}
          </span>
        </div>
      </form>
    </div>
  )
}

export default ProfessionalForm
