import React, { useState,useEffect } from 'react';
import style from "./VrtSession.module.sass";

const SelectButtonRelax = ({ presetRelax,scenery,titlePreset, sessionParameters,onSelect, onSelectValuesChange}) => {  
  const [selectedId, setSelectedId] = useState(null);
  const [selectValues, setSelectValues] = useState({
    code: '',
    time_day: '',
    weather: '',
    sounds: '',
    interactions_colors: '',
    interactions_objects: '',
    interactions_video_feedbacks: '',
    interactions_audio_feedbacks: ''
  });

  const [options, setOptions] = useState({
    time_day: [],
    weather: [],
    sounds: [],
    interactions_colors: [],
    interactions_objects: [],
    interactions_video_feedbacks: [],
    interactions_audio_feedbacks: []
  });

  useEffect(() => {
    console.log(sessionParameters)
    if (sessionParameters) {
      setSelectedId(sessionParameters.selected_preset_id || null);

      let sceneryOptions = scenery.find(s => s.code === sessionParameters.scenery);
      setSelectValues({
        code: sessionParameters.scenery || "",
        time_day: sessionParameters.time_day || "",
        weather: sessionParameters.weather || "",
        sounds: sessionParameters.sounds || "",

        interactions_colors: sessionParameters.interactions_colors ||  "",
        interactions_objects: sessionParameters.interactions_objects ||  "",
        interactions_video_feedbacks: sessionParameters.interactions_video_feedbacks ||  "",
        interactions_audio_feedbacks: sessionParameters.interactions_audio_feedbacks ||  ""
      });
      setOptions({    
        time_day: sceneryOptions.time_dayOptions || [],
        weather: sceneryOptions.weatherOptions || [],
        sounds: sceneryOptions.soundsOptions || [],
        interactions_colors: sceneryOptions.interactions_colorsOptions || [],
        interactions_objects: sceneryOptions.interactions_objectsOptions || [],
        interactions_video_feedbacks: sceneryOptions.interactions_video_feedbacksOptions || [],
        interactions_audio_feedbacks: sceneryOptions.interactions_audio_feedbacksOptions || []
      });
    }

    else if (presetRelax && presetRelax.length > 0) {
      const firstId = presetRelax[0].id;
      setSelectedId(firstId);
      onSelect(firstId);
      updateSelectFields(firstId);
    }
  }, [sessionParameters,presetRelax, onSelect]);



  const handleButtonClick = (e, id) => {
    e.preventDefault();
    setSelectedId(id);
    onSelect(id);
    // Esegui l'azione desiderata quando viene cliccato un bottone
    console.log('Selected ID:', id);
    // Puoi anche inviare una richiesta al server o aggiornare lo stato dell'applicazione
    updateSelectFields(id);
  };

  const updateSelectFields = (id) => {
    const selectedPreset = presetRelax.find(preset => preset.id === id);
    if (selectedPreset) {
      const selectedCode = selectedPreset.code || '';
      const newSelectValues = {
        code: selectedCode,
        time_day: selectedPreset.time_day || '',
        weather: selectedPreset.weather || '',
        sounds: selectedPreset.sounds || '',
        interactions_colors: selectedPreset.interactions_colors || '',
        interactions_objects: selectedPreset.interactions_objects || '',
        interactions_video_feedbacks: selectedPreset.interactions_video_feedbacks || '',
        interactions_audio_feedbacks: selectedPreset.interactions_audio_feedbacks || ''
      };
      
      setSelectValues(newSelectValues);
      onSelectValuesChange(newSelectValues);
      updateOptions(selectedCode);
    }
  };

 

  const updateOptions = (code) => {
    const sceneryOptions = scenery.find(s => s.code === code);
    if (sceneryOptions) {

      setOptions({    
        time_day: sceneryOptions.time_dayOptions || [],
        weather: sceneryOptions.weatherOptions || [],
        sounds: sceneryOptions.soundsOptions || [],
        interactions_colors: sceneryOptions.interactions_colorsOptions || [],
        interactions_objects: sceneryOptions.interactions_objectsOptions || [],
        interactions_video_feedbacks: sceneryOptions.interactions_video_feedbacksOptions || [],
        interactions_audio_feedbacks: sceneryOptions.interactions_audio_feedbacksOptions || []
      });
      // Reset dei campi che non sono disponibili nel nuovo scenario
      setSelectValues(prevValues => ({
        ...prevValues,
        time_day: sceneryOptions.time_dayOptions?.length>0 ? prevValues.time_day : '',
        weather: sceneryOptions.weatherOptions?.length>0 ? prevValues.weather : '',
        sounds: sceneryOptions.soundsOptions?.length>0 ? prevValues.sounds : '',
        interactions_colors: sceneryOptions.interactions_colorsOptions ? prevValues.interactions_colors : 'seleziona interazione',
        interactions_objects: sceneryOptions.interactions_objectsOptions ? prevValues.interactions_objects : 'seleziona interazione',
        interactions_video_feedbacks: sceneryOptions.interactions_video_feedbacksOptions ? prevValues.interactions_video_feedbacks : 'seleziona interazione',
        interactions_audio_feedbacks: sceneryOptions.interactions_audio_feedbacksOptions ? prevValues.interactions_audio_feedbacks : 'seleziona interazione'
    }));


    }
  };

  const updateOptionCode = (code) => {
    const sceneryOptions = scenery.find(s => s.code === code);
    if (sceneryOptions) {

      const newOptions = {    
        time_day: sceneryOptions.time_dayOptions || [],
        weather: sceneryOptions.weatherOptions || [],
        sounds: sceneryOptions.soundsOptions || [],
        interactions_colors: sceneryOptions.interactions_colorsOptions || [],
        interactions_objects: sceneryOptions.interactions_objectsOptions || [],
        interactions_video_feedbacks: sceneryOptions.interactions_video_feedbacksOptions || [],
        interactions_audio_feedbacks: sceneryOptions.interactions_audio_feedbacksOptions || []
      };

      setOptions(newOptions);
      // Reset dei campi che non sono disponibili nel nuovo scenario
      setSelectValues(prevValues => ({
        ...prevValues,
        time_day: sceneryOptions.time_dayOptions?.length>0 ?  newOptions.time_day[0]: '',
        weather: sceneryOptions.weatherOptions?.length>0 ?  newOptions.weather[0] : '',
        sounds: sceneryOptions.soundsOptions?.length>0 ? newOptions.sounds[0] : '',
        interactions_colors: sceneryOptions.interactions_colorsOptions ? prevValues.interactions_colors : 'seleziona interazione',
        interactions_objects: sceneryOptions.interactions_objectsOptions ? prevValues.interactions_objects : 'seleziona interazione',
        interactions_video_feedbacks: sceneryOptions.interactions_video_feedbacksOptions ? prevValues.interactions_video_feedbacks : 'seleziona interazione',
        interactions_audio_feedbacks: sceneryOptions.interactions_audio_feedbacksOptions ? prevValues.interactions_audio_feedbacks : 'seleziona interazione'
    }));

    // Aggiorna i valori selezionati con i nuovi valori
    onSelectValuesChange({
      code,
      time_day: newOptions.time_day.length > 0 ? newOptions.time_day[0] : '',
      weather: newOptions.weather.length > 0 ? newOptions.weather[0] : '',
      sounds: newOptions.sounds.length > 0 ? newOptions.sounds[0] : ''
    });
    }
  };

  const handleSelectChange = (e, selectName) => {
    const value = e.target.value;
    let newSelectValues = {
      ...selectValues,
      [selectName]: value
    };
    
    if (['interactions_colors', 'interactions_objects', 'interactions_video_feedbacks', 'interactions_audio_feedbacks'].includes(selectName)) {
      newSelectValues = {
          ...newSelectValues,
          interactions_colors: selectName === 'interactions_colors' ? value : '',
          interactions_objects: selectName === 'interactions_objects' ? value : '',
          interactions_video_feedbacks: selectName === 'interactions_video_feedbacks' ? value : '',
          interactions_audio_feedbacks: selectName === 'interactions_audio_feedbacks' ? value : ''
      };
  }

    setSelectValues(newSelectValues);
    onSelectValuesChange(newSelectValues);

    if (selectName === 'code') {
      updateOptionCode(value);
    }
  };

  return (
    <div className={style.formField}>
      <div className={`${style.left}  ${style.labelField}`}> {titlePreset}</div>
      <div className={style.right}> 
        {presetRelax.map(preset => (
          <button
            key={preset.id}
            className={`${style.select_button} ${selectedId === preset.id ? style.selected : ''}`} // Puoi usare la stessa classe per lo stile
            onClick={(e) => handleButtonClick(e, preset.id)}
            style={{ marginBottom: "12px" }}
          >
            {preset.id}
          </button>
        ))}
                  
      </div>
      <div className={style.voidRight} />
      <div className={`${style.left}  ${style.labelField}`}> Scenario </div>
      <div className={style.right}> 
        <select value={selectValues.code} onChange={(e) => handleSelectChange(e, 'code')}>
          {scenery.map(s => (
            <option key={s.code} value={s.code}>
              {s.code}
            </option>
          ))}
        </select>
      </div>      
      <div className={style.voidRight} />
      <div className={`${style.left}  ${style.labelField}`}> {(options.time_day.length > 0 || selectValues.time_day)  && 'Condizioni di luce'}</div>
      <div className={style.right}>     
        {(options.time_day.length > 0 || selectValues.time_day) && (
          <select value={selectValues.time_day} onChange={(e) => handleSelectChange(e, 'time_day')}>
            {options.time_day.map((option, index) => (
              <option key={index} value={option}>
                {option}
              </option>
            ))}
          </select>
        )}
      </div>
      <div className={style.voidRight} />

      <div className={`${style.left}  ${style.labelField}`}> { (options.weather.length > 0 || selectValues.weather)  && 'Clima'}</div>
      <div className={style.right}> 
        {(options.weather.length > 0 || selectValues.weather) && (
          <select value={selectValues.weather} onChange={(e) => handleSelectChange(e, 'weather')}>
            {options.weather.map((option, index) => (
              <option key={index} value={option}>
                {option}
              </option>
            ))}
          </select>
        )}
      </div>
      <div className={style.voidRight} />

      <div className={`${style.left}  ${style.labelField}`}> {(options.sounds.length > 0 || selectValues.sounds) && 'Suoni ambientali e musica'}</div>
      <div className={style.right}> 
        {(options.sounds.length > 0 || selectValues.sounds) && (
          <select value={selectValues.sounds} onChange={(e) => handleSelectChange(e, 'sounds')}>
            {options.sounds.map((option, index) => (
              <option key={index} value={option}>
                {option}
              </option>
            ))}
          </select>
        )}
      </div>
      <div className={style.voidRight} />

      {/* Nuove Select Box per le Interazioni */}
      <div className={`${style.left}  ${style.labelField}`}> {options.interactions_colors.length > 0 && 'Interazioni con il colore'}</div>
      <div className={style.right}> 
        {options.interactions_colors.length > 0 && (
          <select value={selectValues.interactions_colors} onChange={(e) => handleSelectChange(e, 'interactions_colors')}>
            <option value="">Seleziona interazione</option>
            {options.interactions_colors.map((option, index) => (
              <option key={index} value={option}>
                {option}
              </option>
            ))}
          </select>
        )}
      </div>
      <div className={style.voidRight} />

      <div className={`${style.left}  ${style.labelField}`}> {options.interactions_objects.length > 0 && 'Interazioni di raccolta e movimento oggetti'}</div>
      <div className={style.right}> 
        {options.interactions_objects.length > 0 && (
          <select value={selectValues.interactions_objects} onChange={(e) => handleSelectChange(e, 'interactions_objects')}>
            <option value="">Seleziona interazione</option>
            {options.interactions_objects.map((option, index) => (
              <option key={index} value={option}>
                {option}
              </option>
            ))}
          </select>
        )}
      </div>
      <div className={style.voidRight} />

      <div className={`${style.left}  ${style.labelField}`}> {options.interactions_video_feedbacks.length > 0 && 'Interazioni con feedback visivi'}</div>
      <div className={style.right}> 
        {options.interactions_video_feedbacks.length > 0 && (
          <select value={selectValues.interactions_video_feedbacks} onChange={(e) => handleSelectChange(e, 'interactions_video_feedbacks')}>
            <option value="">Seleziona interazione</option>
            {options.interactions_video_feedbacks.map((option, index) => (
              <option key={index} value={option}>
                {option}
              </option>
            ))}
          </select>
        )}
      </div>
      <div className={style.voidRight} />

      <div className={`${style.left}  ${style.labelField}`}> {options.interactions_audio_feedbacks.length > 0 && 'Interazioni con feedback audio'}</div>
      <div className={style.right}> 
        {options.interactions_audio_feedbacks.length > 0 && (
          <select value={selectValues.interactions_audio_feedbacks} onChange={(e) => handleSelectChange(e, 'interactions_audio_feedbacks')}>
            <option value="">Seleziona interazione</option>
            {options.interactions_audio_feedbacks.map((option, index) => (
              <option key={index} value={option}>
                {option}
              </option>
            ))}
          </select>
        )}
      </div>
      <div className={style.voidRight} />
    </div>


  
  );
};

export default SelectButtonRelax;
