import React, { useState, useEffect } from "react";
import VrtSessionForm from "./VrtSessionForm";

function VrtSessionEdit({ editProps }) {
  const {
    session,
    sessionParameters,
    tipologies,
    presetRelax,
    sceneryRelax,
    titlePreset,
    sceneryEducational,
    titleEducationalLevel,
    levelName,
    presetEducational,
    updateUrl,
  } = editProps;

  const [formState, setFormState] = useState(null);

  useEffect(() => {
    console.log("debug="+sessionParameters)
    if (sessionParameters) {
      setFormState({
        ...sessionParameters,
        vrt_session_typology_id: session.vrt_typology_session_id,
      });
    }
  }, [sessionParameters, session]);

  const handleSubmit = async (formData) => {
    try {
      const response = await fetch(updateUrl, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ sessions: formData }),
      });

      const data = await response.json();
      if (response.ok) {
        window.location.href = data.redirectUrl;
      } else {
        console.error("Error updating session:", data.errors);
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  if (!formState) {
    return <div>Loading...</div>; // Mostra un caricamento finché i dati non sono disponibili
  }

  return (
    <VrtSessionForm
      {...editProps}
      session={session}
      initialFormState={formState} // Passa lo stato iniziale al form
      onSubmit={handleSubmit}
    />
  );
}

export default VrtSessionEdit;
