import React, { useEffect, useRef } from "react"
import style from "./EuleriagymEvaluationResult.module.sass"

function EuleriagymEvaluationResult(props) {
  const {
    label,
    left,
    right,
    difference,
    graphLimit,
    monolateral
  } = props

  const chart = useRef()

  useEffect(() => {
    if (chart.current.children.length == 0) {
      let canvas = document.createElement("canvas")
      chart.current.appendChild(canvas)
      let leftData = (left.value == "-") ? 0 : parseInt(left.value.replace("°", ""))
      let rightData = (right.value == "-") ? 0 : parseInt(right.value.replace("°", ""))
      
      new Chart(canvas, {
        type: 'bar',
        data: {
          labels: monolateral==true? [left.label]  : [left.label, right.label],
          datasets: [{
            label: label,
            data: monolateral==true? [leftData] : [leftData, rightData],
            backgroundColor: monolateral==true ? ["#77bdff"] : ["#B7DCFE", "#FF8197"]
          }]
        },
        options: {
          layout: {
            padding: {
              top: 7
            }
          },
          animation: {
            duration: 0
          },
          maintainAspectRatio: false,
          legend: {
            display: false
          },
          elements: {
            bar: {
              borderSkipped: false
            }
          },
          hover: {
            mode: null
          },
          tooltips: {
            enabled: false
          },
          scales: {
            xAxes: [{
              gridLines: {
                display: false
              },
              barPercentage:monolateral==true? 0.25 : 0.5,
              categoryPercentage: 1,
              ticks: {
                display: false,
                beginAtZero: true
              }
            }],
            yAxes: [{
              gridLines: {
                display: true,
                color: "#4E5E70",
                zeroLineColor: "#96A5B6"
              },
              ticks: {
                fontColor: "#96A5B6",
                fontSize: 10,
                fontFamily: "Mont",
                beginAtZero: true,
                fixedStepSize: graphLimit / 5,
                suggestedMax: graphLimit,
                callback: function(value) {
                  return value + "° "
                }
              }
            }]
          }
        }
      })
    }
  }, [props])

  

  const maxSideClass = (thisSide, otherSide) => {
    if (thisSide != "-" && (otherSide == "-" || thisSide >= otherSide)) {
      return style.maxValue
    }
  }

  const differenceBorderClass = () => {
    if (difference.value != "-") {
      if (left.value > right.value) {
        return style.left
      } else if (right.value > left.value) {
        return style.right
      }
    }
  } 

  if (monolateral == false) {
    return (
      <div className={style.evaluationResult}>
        <div className={style.title}>
          {label}
          </div>
        <div className={style.leftSide}>
          <div className={style.label}>{left.label}</div>
          <div className={`${style.value} ${maxSideClass(left.value, right.value)}`}>{left.value}</div>
        </div>
        <div className={style.rightSide}>
          <div className={style.label}>{right.label}</div>
          <div className={`${style.value} ${maxSideClass(right.value, left.value)}`}>{right.value}</div>
        </div>
        <div className={style.difference}>
          <div className={style.label}>{difference.label}</div>
          <div className={`${style.value} ${differenceBorderClass()}`}>{difference.value}</div>
        </div>
        <div className={style.chart} ref={chart}>
        </div>
      </div>
    )
  }
  else{
    return (
      <div className={style.evaluationResult}>
        <div className={`${style.title} ${style.no_border}`}>
          {label}
          </div>
        <div className={style.noneSide}>
          <div className={style.label}>{left.label}</div>
          <div className="">&nbsp;</div>
        </div>
        <div className={style.noneSideCenter}>
          <div className={style.label}>{right.label}</div>
          <div className={`${style.value} ${maxSideClass(left.value, right.value)}`}>{left.value}</div>
        </div>
        <div className={style.noneSide}>
          <div className={style.label}>{difference.label}</div>
          <div className={`${style.value} ${differenceBorderClass()}`}>&nbsp;</div>
        </div>
        <div className={style.chart} ref={chart}>
        </div>
      </div>
    )   
  }
}

export default EuleriagymEvaluationResult