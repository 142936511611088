import React from "react"
import style from "./ProfessionalForm.module.sass"

function CommonFields(props) {
  const {
    errors,
    fieldsTranslations,
    fieldLabelStyle,
    inputErrorClass,
    professional,
    toggleFieldLabel } = props

  const addInvalidClass = (e, field) => {
    e.preventDefault()
    document.getElementById(field).classList.add(style.invalidInput)
  }

  return (
    <span>
      {/* <div className={style.inputError}>{errors["gender"]}</div> */}
      <div>
        <span className={`${style.requiredField}`}>*</span>
        <div id="lastname-label" style={fieldLabelStyle(professional.lastname)}>
          <span className={style.floatPlaceholder}>{fieldsTranslations.lastname}</span>
        </div>
        <input type='text'
          placeholder={fieldsTranslations.lastname}
          autoComplete="off"
          className={`${style.fullWidthInput} ${inputErrorClass('lastname')}`}
          defaultValue={professional.lastname}
          id='lastname'
          required
          onInvalid={(e) => addInvalidClass(e, 'lastname')}
          onChange={(e) => toggleFieldLabel(e)}
          name='lastname' />
        {/* <div className={style.inputError}>{errors["lastname"]}</div> */}
      </div>
      <div>
        <span className={`${style.requiredField}`}>*</span>
        <div id="name-label" style={fieldLabelStyle(professional.name)}>
          <span className={style.floatPlaceholder}>{fieldsTranslations.name}</span>
        </div>
        <input type='text'
          placeholder={fieldsTranslations.name}
          autoComplete="off"
          className={`${style.fullWidthInput} ${inputErrorClass('name')}`}
          defaultValue={professional.name}
          id='name'
          required
          onInvalid={(e) => addInvalidClass(e, 'name')}
          onChange={(e) => toggleFieldLabel(e)}
          name='name' />
        {/* <div className={style.inputError}>{errors["name"]}</div> */}
      </div>
      <div>
        <span className={`${style.requiredField}`}>*</span>
        <div id="email-label" style={fieldLabelStyle(professional.email)}>
          <span className={style.floatPlaceholder}>{fieldsTranslations.email}</span>
        </div>
        <input type='text'
          placeholder={fieldsTranslations.email}
          autoComplete="off"
          className={`${style.fullWidthInput} ${inputErrorClass('email')}`}
          defaultValue={professional.email}
          id='email'
          required
          onInvalid={(e) => addInvalidClass(e, 'email')}
          onChange={(e) => toggleFieldLabel(e)}
          name='email' />
        <div className={style.inputError}>{errors["email"]}</div>
      </div>
    </span>
  )
}

export default CommonFields
