import React, { Fragment, useState } from "react";
import { debounce, triggerTextChange } from "../../../helpers";
import Select from "../../Select/Select";
import classes from "./ProfessionalListHeader.module.sass"

function ProfessionalListHeader(props) {
  const {
    createProfessional,
    onChangeFilter,
    searchSelectOptions,
    searchProfessionalsPlaceholderText } = props;

  const [orderBy, setOrderBy] = useState(searchSelectOptions[0].value);
  const [search, setSearch] = useState("");
  const [direction, setDirection] = useState("asc");

  const orderBySetter = ((option) => {
    setOrderBy(option.value)
    let newDirection = searchSelectOptions.filter(opt => opt.value == option.value && opt.direction == option.direction)[0]
    newDirection = newDirection ? newDirection.direction : "asc"
    setDirection(newDirection)
    onChangeFilter(option.value, search, newDirection)
  })

  const handleSearchChange = debounce((e) => {
    triggerTextChange(
      e,
      (text) => {
        setSearch(text);
        onChangeFilter(orderBy, text, direction ? direction : "asc");
      },
      search
    );
  }, 200);

  const userIsSearching = (e) => {
    handleSearchChange(e);
  };

  const goToNewProfessional = () => {
    window.location.href = createProfessional.url
  }

  return (
    <Fragment>
      <div className={`col-8 d-flex`} style={{ gap: "20px" }}>
        <div className={classes.searchBar}>
          <i className={`fa fa-search ${classes.iconSearch}`}></i>
          <input
            type="search"
            id="professional-search"
            className={`form-control input-kari`}
            onChange={userIsSearching}
            placeholder={searchProfessionalsPlaceholderText}
          />
        </div>
        <div style={{ width: "300px", position: "relative" }}>
          <Select
            options={searchSelectOptions}
            setOption={orderBySetter}
            selectedOption={searchSelectOptions[0].label}
          />
        </div>
      </div>
      <div className={`col-4 d-flex justify-content-end`}>
        <div className={classes.professionalButton} onClick={() => { goToNewProfessional() }}>
          {createProfessional.text}
        </div>
      </div>
    </Fragment>
  );
}

export default ProfessionalListHeader;
