import React, { useState } from "react"
import { callApi, debounce, triggerTextChange } from "../../../helpers";
import style from "./SearchAndFilters.module.sass"
import Spinner from "../../PatientForm/Spinner"
import check from "../../../../../assets/images/check-small.svg"

function SearchAndFilters(props) {
  const { 
    activeLicenses, 
    currentLicense, 
    hasEuleriaHomeActivities,
    isDemo,
    licensesData, 
    setCurrentLicense, 
    setCurrentSearch, 
    searchActivityPlaceholderText,
    sendResults } = props

  const [search, setSearch] = useState("")
  const [sendResultsState, setSendResultState] = useState("todo")

  const handleSearchChange = debounce((e) => {
    triggerTextChange(
      e,
      (text) => {
        setSearch(text);
        setCurrentSearch(text)
      },
      search,
      1
    );
  }, 200);

  const licenseSwitchClass = (license) => {
    let switchClass = {}
    if(license == currentLicense){
      switchClass = {
        backgroundColor: licensesData[license].color,
        cursor: "default",
        color: "#16181C",
        fontFamily: "Mont",
        fontStyle: "normal",
        fontWeight: "700",
        fontSize: "14px"
      }
    }
    return switchClass
  }

  const handleSwitch = (license) => {
    setCurrentLicense(license)
    if(search != "") {
      setSearch("")
      setCurrentSearch("")
    }
  }

  const switchCol = () => {
    console.log(activeLicenses)
    if (activeLicenses.length == 2) {return 6}
    if (activeLicenses.length == 3) {return 4}
    if (activeLicenses.length == 4) {return 3}
  }

  const sendAllData = () => {
    setSendResultState("doing")
    callApi(sendResults.url, "POST", "", () => {
      setSendResultState("done")
    })
  }

  return (
    <div className={`row ${style.searchAndFiltersBar}`}>
      <div className={`col`}>
        {currentLicense == "euleria_lab" &&
          <div className={style.searchBar}>
            <i className={`fa fa-search ${style.iconSearch}`}></i>
            <input type="search"
                   className={`form-control input-kari`}
                   placeholder={searchActivityPlaceholderText}
                   onChange={handleSearchChange}>
            </input>
          </div>
        }
        {!isDemo && currentLicense == "euleria_home" && hasEuleriaHomeActivities &&
          <div className={style.sendResults}>
            <div className={style.button} onClick={() => sendAllData()}>
              {sendResults.label}
            </div>
            {sendResultsState == 'doing' && 
              <div style={{paddingTop: "5px"}}>
                <Spinner/>
              </div>
            }
            {sendResultsState == 'done' && 
              <div>
                <img src={check} width="15" height="15"/>
              </div>
            }
          </div>
        }
      </div>
      <div className={`col align-self-center`}>
        {activeLicenses.length > 1 && 
          <div className={`text-center ${style.licenseSwitch}`}>
            <div className={`row ${style.licenseWrapper}`}>
              {
                activeLicenses.map(license => {
                  return (
                    <div onClick={() => handleSwitch(license)} 
                         key={license}
                         id={license}
                         className={`col-${switchCol()} ${style.license}`} 
                         style={licenseSwitchClass(license)}>
                      {licensesData[license].name}
                    </div>
                  )
                })
              }
            </div>
          </div>
        }
      </div>
    </div>
  )
}

export default SearchAndFilters