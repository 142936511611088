import React from "react";

const CommonPlans = (props) => {
  return (
  <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M23.5 16H36.5" stroke="#F5FAFF" stroke-width="2" stroke-linecap="round"/>
  <rect x="13.25" y="13.25" width="5.5" height="5.5" rx="1.25" stroke="#F5FAFF" stroke-width="1.5"/>
  <path d="M23.5 25H36.5" stroke="#F5FAFF" stroke-width="2" stroke-linecap="round"/>
  <rect x="13.25" y="22.25" width="5.5" height="5.5" rx="1.25" stroke="#F5FAFF" stroke-width="1.5"/>
  <path d="M23.5 34H36.5" stroke="#F5FAFF" stroke-width="2" stroke-linecap="round"/>
  <rect x="13.25" y="31.25" width="5.5" height="5.5" rx="1.25" stroke="#F5FAFF" stroke-width="1.5"/>
  </svg>
  
  );
};

export default CommonPlans;
