import React from 'react';
import style from "./VrtSession.module.sass";

const RelaxScenarioSelectBoxes = ({ options, selectValues, handleSelectChange }) => {
  return (
    <>
      <div className={`${style.left}  ${style.labelField}`}> {options.time_day.length > 0 && 'Condizioni di luce'}</div>
      <div className={style.right}>     
        {options.time_day.length > 0 && (
          <select value={selectValues.time_day} onChange={(e) => handleSelectChange(e, 'time_day')}>
            {options.time_day.map((option, index) => (
              <option key={index} value={option}>
                {option}
              </option>
            ))}
          </select>
        )}
      </div>
      <div className={style.voidRight} />

      <div className={`${style.left}  ${style.labelField}`}> {options.weather.length > 0 && 'Clima'}</div>
      <div className={style.right}> 
        {options.weather.length > 0 && (
          <select value={selectValues.weather} onChange={(e) => handleSelectChange(e, 'weather')}>
            {options.weather.map((option, index) => (
              <option key={index} value={option}>
                {option}
              </option>
            ))}
          </select>
        )}
      </div>
      <div className={style.voidRight} />

      <div className={`${style.left}  ${style.labelField}`}> {options.sounds.length > 0 && 'Suoni ambientali e musica'}</div>
      <div className={style.right}> 
        {options.sounds.length > 0 && (
          <select value={selectValues.sounds} onChange={(e) => handleSelectChange(e, 'sounds')}>
            {options.sounds.map((option, index) => (
              <option key={index} value={option}>
                {option}
              </option>
            ))}
          </select>
        )}
      </div>
      <div className={style.voidRight} />

      {/* Nuove Select Box per le Interazioni */}
      <div className={`${style.left}  ${style.labelField}`}> {options.interactions_colors.length > 0 && 'Interazioni con il colore'}</div>
      <div className={style.right}> 
        {options.interactions_colors.length > 0 && (
          <select value={selectValues.interactions_colors} onChange={(e) => handleSelectChange(e, 'interactions_colors')}>
            <option value="">Seleziona interazione</option>
            {options.interactions_colors.map((option, index) => (
              <option key={index} value={option}>
                {option}
              </option>
            ))}
          </select>
        )}
      </div>
      <div className={style.voidRight} />

      <div className={`${style.left}  ${style.labelField}`}> {options.interactions_objects.length > 0 && 'Interazioni di raccolta e movimento oggetti'}</div>
      <div className={style.right}> 
        {options.interactions_objects.length > 0 && (
          <select value={selectValues.interactions_objects} onChange={(e) => handleSelectChange(e, 'interactions_objects')}>
            <option value="">Seleziona interazione</option>
            {options.interactions_objects.map((option, index) => (
              <option key={index} value={option}>
                {option}
              </option>
            ))}
          </select>
        )}
      </div>
      <div className={style.voidRight} />

      <div className={`${style.left}  ${style.labelField}`}> {options.interactions_video_feedbacks.length > 0 && 'Interazioni con feedback visivi'}</div>
      <div className={style.right}> 
        {options.interactions_video_feedbacks.length > 0 && (
          <select value={selectValues.interactions_video_feedbacks} onChange={(e) => handleSelectChange(e, 'interactions_video_feedbacks')}>
            <option value="">Seleziona interazione</option>
            {options.interactions_video_feedbacks.map((option, index) => (
              <option key={index} value={option}>
                {option}
              </option>
            ))}
          </select>
        )}
      </div>
      <div className={style.voidRight} />

      <div className={`${style.left}  ${style.labelField}`}> {options.interactions_audio_feedbacks.length > 0 && 'Interazioni con feedback audio'}</div>
      <div className={style.right}> 
        {options.interactions_audio_feedbacks.length > 0 && (
          <select value={selectValues.interactions_audio_feedbacks} onChange={(e) => handleSelectChange(e, 'interactions_audio_feedbacks')}>
            <option value="">Seleziona interazione</option>
            {options.interactions_audio_feedbacks.map((option, index) => (
              <option key={index} value={option}>
                {option}
              </option>
            ))}
          </select>
        )}
      </div>
      <div className={style.voidRight} />
    </>
  );
};

export default RelaxScenarioSelectBoxes;
