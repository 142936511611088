// File: components/LeftMenu/PlanDropdown.js

import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import style from "./PlanDropdown.module.sass";

function PlanDropdown(props) {
  const { plans, icon, tooltip, initModal, id, active } = props;

  const rootRef = useRef();
  const [isOpen, setIsOpen] = useState(false);
  const [isClicked, setIsClicked] = useState(false);

  // Filtra i piani attivi
  const activePlans = plans.filter((plan) => plan.active);

  // Funzione per gestire il clic su un piano
  const handleSinglePlan = (plan) => {
    setIsOpen((prevIsOpen) => !prevIsOpen);
    setIsClicked(true);
    if (plan.modal) {
      const uniqueUrl = `${plan.url}&rand=${Math.random()
        .toString(36)
        .substring(2, 15)}`;
      initModal(uniqueUrl, plan.label);

      const modalElement = document.getElementById("EuleriaLabSequencesModal");
      if (modalElement) {
        modalElement.classList.add("show");
        modalElement.style.display = "block";

        // Aggiungiamo un listener per chiudere il modale e riabilitare l'icona
        modalElement.addEventListener(
          "hidden.bs.modal",
          () => {

            setIsClicked(false); // Permette di riaprire
          },
          { once: true }
        );
      }
    } else if (plan.url) {
      window.location.href = plan.url;
    }
  };

  // Funzione per aprire/chiudere il dropdown o aprire un singolo piano
  const toggleDropdown = (e) => {
    e.preventDefault();

    if (activePlans.length === 1) {

      // Se c'è un solo piano attivo, aprilo direttamente
      handleSinglePlan(activePlans[0]);

    } else {
      // Altrimenti, apri/chiudi il dropdown
      setIsOpen((prevIsOpen) => !prevIsOpen);
      setIsClicked(true);
    }
  };

  // Funzione per renderizzare i piani
  const renderPlan = (plan) => {
    if (!plan.active) return null;

    const onClickPlan = (e) => {
      e.preventDefault();
      handleSinglePlan(plan);
    };

    return (
      <li
        key={plan.label}
        className={style.dropdownItem}
        onClick={onClickPlan}
      >
        <span>{plan.label}</span>
      </li>
    );
  };

  const styleForActiveIcon = () => {
    if (isClicked) {
      return style.clickedDropdown;
    }
    return active ? style.activeDropdown : style.inactiveDropdown;
  };

  return (
    <div className={style.dropdownWrapper} ref={rootRef}>
      {/* Pulsante principale con icona */}
      <div
        className={`auto-tooltip ${styleForActiveIcon()} ${
          style.dropdownToggle
        }`}
        data-toggle="tooltip"
        data-placement="right"
        title={tooltip}
        id={id}
        onClick={toggleDropdown} // Gestisce il clic
      >
        <a href="#" onClick={(e) => e.preventDefault()}>
          {icon}
        </a>
      </div>

      {/* Menu a tendina */}
      {isOpen && activePlans.length > 1 && (
        <ul className={style.dropdownMenu}>
          {plans.map((plan) => renderPlan(plan))}
        </ul>
      )}
    </div>
  );
}

PlanDropdown.propTypes = {
  plans: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      active: PropTypes.bool.isRequired,
      modal: PropTypes.bool.isRequired,
      url: PropTypes.string,
    })
  ).isRequired,
  icon: PropTypes.node.isRequired,
  tooltip: PropTypes.string,
  initModal: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  active: PropTypes.bool,
};

PlanDropdown.defaultProps = {
  tooltip: "Apri il menu",
  active: false,
};

export default PlanDropdown;
