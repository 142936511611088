import React, { useState } from "react";
import style from "./Professionals.module.sass";
import ProfessionalListHeader from "./ProfessionalListHeader/ProfessionalListHeader";
import ProfessionalBox from "../ProfessionalBox/ProfessionalBox";
import List from "../List/List";


function Professionals(props) {
  const {
    assignModal,
    createProfessional,
    searchSelectOptions,
    searchProfessionalsPlaceholderText } = props;

  const baseUrl = "/professionals.json";
  const [queryUrl, setQueryUrl] = useState(
    baseUrl + "?name=&order_by=&direction=desc"
  );

  const onChangeFilter = (newOrder, search = "", direction = "") => {
    let newQueryUrl =
      baseUrl +
      "?name=" +
      search +
      "&order_by=" +
      newOrder +
      "&direction=" +
      direction;
    setQueryUrl(newQueryUrl);
  };

  const archiveReload = () => {
    setQueryUrl(queryUrl + " ")
  }

  const renderProfessionalItem = (item) => {
    return <ProfessionalBox
      {...item}
      archiveRedirect={archiveReload}
      assignModal={assignModal}
    />;
  };

  return (
    <div className={`${style.ProfessionalsList}`}>
      <div className={"row mb-4 text-white"}>
        <ProfessionalListHeader
          createProfessional={createProfessional}
          onChangeFilter={onChangeFilter}
          searchSelectOptions={searchSelectOptions}
          searchProfessionalsPlaceholderText={searchProfessionalsPlaceholderText}
        />
      </div>
      <div className={style.List}>
        <List baseQuery={queryUrl} renderItem={renderProfessionalItem} />
      </div>
    </div>
  );
}

export default Professionals;
