import React, { useState, useEffect, useRef } from "react";
import style from "./PatientMenu.module.sass";
import rentals from "../../../../../assets/images/rentals.svg"
import trash from "../../../../../assets/images/red-trash-without-circle.svg"
import pencil from "../../../../../assets/images/empty-pencil.svg"
import objective from "../../../../../assets/images/star.svg"
import assign from "../../../../../assets/images/assign.svg"
import Modal from "../../Modal/Modal";
import Select from "../../Select/Select";

function PatientMenu(props) {
  const {
    actionsUrls,
    activeActions,
    archiveRedirect,
    archiveTranslations,
    assignTranslations,
    assignModal,
    isDemo,
    patientName,
    translations,
  } = props;

  const [archiveState, setArchiveState] = useState("");
  const [assignState, setAssignState] = useState("");
  const [enableDelete, setEnableDelete] = useState(false);
  const [enableAssign, setEnableAssign] = useState(false);
  const [selectedProfessionalId, setSelectedProfessionalId] = useState("");
  const [showMenu, setShowMenu] = useState(false);

  const ref = useRef();

  const handleClick = (e) => {
    e.preventDefault()
    e.stopPropagation()
    setShowMenu(!showMenu)
  }

  const actionClick = (e, url) => {
    e.stopPropagation()
    window.location.href = url
  }

  const archivePatient = (e, url) => {
    fetch(url, { method: 'POST' })
      .then((response) => {
        if (response.status == 200) {
          e.stopPropagation()
          setArchiveState("success")
        } else {
          e.stopPropagation()
          setArchiveState("error")
        }
      })
    e.preventDefault();
    e.stopPropagation();
  }

  const assignPatient = (e, url) => {
    const data = { professional: { target_id: selectedProfessionalId } }

    fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    })
      .then((response) => {
        if (response.status == 200) {
          e.stopPropagation()
          setAssignState("success")
        } else {
          e.stopPropagation()
          setAssignState("error")
        }
      })
    e.preventDefault();
    e.stopPropagation();
  }

  useOnClickOutside(ref, () => setShowMenu(false));

  const enableDeleteButton = (e) => {
    e.stopPropagation();
    setEnableDelete(!enableDelete);
  }

  const enableAssignButton = (option) => {

    // e.stopPropagation();
    setSelectedProfessionalId(option.value)
    setEnableAssign(option.value != "");
  }

  const cancelPatientArchive = (e) => {
    e.stopPropagation()
    setEnableDelete(false)
  }

  const closeArchiveModal = (e) => {
    e.stopPropagation()
    setEnableDelete(false)
    setShowMenu(false)
    archiveRedirect()
  }

  const closeAssignModal = (e) => {
    e.stopPropagation()
    setEnableAssign(false)
    setShowMenu(false)
    setAssignState("")
    archiveRedirect()
  }

  const dropDownClass = () => {
    if (activeActions.includes("show_sessions") || activeActions.includes("edit_sessions")) {
      return `${style.dropDown} ${style.large}`
    }
    return style.dropDown
  }

  const dropDownItem = (actionUrl, translation, src) => {
    return (
      <div className={`${style.dropDownItem}`}
        onClick={(e) => actionClick(e, actionUrl)}>
        <div className={style.iconContainer}>
          <img src={src} className={style.icon} />
        </div>
        <div style={{ paddingTop: "5px", paddingBottom: "5px" }}>
          {translation}
        </div>
      </div>
    )
  }

  const renderAssignModal = () => {
    return (
      <Modal
        background={true}
        closeButtons={["#cancelAssign"]}
        closeOnBackgroundClick={false}
        triggerButtons={["#confirmAssign"]} >
        <div className={style.deleteModal} onClick={(e) => e.stopPropagation()}>
          {assignState == "" &&
            <div>
              <div className={`${style.title} ${style.assignTitle}`}>
                {assignTranslations.title}
              </div>
              <div className={style.subTitle}>
                {assignTranslations.text}
              </div >
              <div className={style.selectContainer}>
                <div className={style.selectLabel}>
                  {assignModal.selectLabel}
                </div>
                <div className={style.selectDropdown}>
                  <Select
                    options={assignModal.selectOptions}
                    setOption={enableAssignButton}
                    prompt={assignModal.selectPrompt}
                  />
                </div>
              </div>
              <div className={style.buttonsWrapper}>
                <div className={style.cancel}
                  id="cancelAssign"
                  onClick={(e) => cancelPatientArchive(e)}>
                  {assignTranslations.cancel}
                </div>
                <div className={enableAssign ? style.assign : style.disabledAssign}
                  onClick={(e) => assignPatient(e, actionsUrls.assignPatient)}>
                  {assignTranslations.assign}
                </div>
              </div>
            </div>
          }
          {assignState == "success" &&
            <div className={style.onResponse}>
              <div className={style.successTitle}> {assignTranslations.success}</div>
              <div className={style.subTitle}>
                {assignTranslations.successMessage}
              </div>
              <div className={style.disclaimer}>
                {assignTranslations.closeMessage}
              </div>
              <div className={style.buttonsWrapper}>
                <div className={style.cancel}
                  id="cancelAssign"
                  onClick={(e) => closeAssignModal(e)}>
                  {assignTranslations.close}
                </div>
              </div>
            </div>
          }
          {assignState == "error" &&
            <div className={style.onResponse}>
              <div>
                <div className={style.title}> {assignTranslations.error}</div>
                <div className={style.subTitle}>
                  {assignTranslations.errorMessage}
                  <br />
                  <br />
                  supporto@euleria.it
                </div>
                <div className={style.disclaimer}>
                  {assignTranslations.closeMessage}
                </div>
                <div className={style.cancel}
                  id="cancelArchive"
                  onClick={(e) => closeAssignModal(e)}>
                  {assignTranslations.close}
                </div>
              </div>
            </div>
          }
        </div>
      </Modal>
    )
  }

  const renderArchiveModal = () => {
    return (
      <Modal
        background={true}
        closeButtons={["#cancelArchive"]}
        closeOnBackgroundClick={false}
        triggerButtons={["#confirmDelete"]} >
        <div className={style.deleteModal} onClick={(e) => e.stopPropagation()}>
          {archiveState == "" &&
            <div>
              <div className={style.title}>
                {archiveTranslations.areYouSure}
              </div>
              <div className={style.subTitle}>
                {archiveTranslations.patientData1} <span className={style.patientName}>{patientName}</span> {archiveTranslations.patientData2}
              </div>
              <div className={style.disclaimer}>
                {archiveTranslations.patientConsent}
              </div>
              <div className={style.checkboxWrapper}>
                <input type="checkbox"
                  id="confirmCheck"
                  onChange={(e) => enableDeleteButton(e)} />
                <label htmlFor="confirmCheck"> {archiveTranslations.confirm}</label>
              </div>
              <div className={style.buttonsWrapper}>
                <div className={style.cancel}
                  id="cancelArchive"
                  onClick={(e) => cancelPatientArchive(e)}>
                  {archiveTranslations.cancel}
                </div>
                <div className={enableDelete ? style.delete : style.disabledDelete}
                  onClick={(e) => archivePatient(e, actionsUrls.deleteUser)}>
                  {archiveTranslations.delete}
                </div>
              </div>
            </div>
          }
          {archiveState == "success" &&
            <div className={style.onResponse}>
              <div className={style.successTitle}> {archiveTranslations.success}</div>
              <div className={style.subTitle}>
                {archiveTranslations.successMessage}
              </div>
              <div className={style.disclaimer}>
                {archiveTranslations.closeMessage}
              </div>
              <div className={style.cancel}
                id="cancelArchive"
                onClick={(e) => closeArchiveModal(e)}>
                {archiveTranslations.close}
              </div>
            </div>
          }
          {archiveState == "error" &&
            <div className={style.onResponse}>
              <div>
                <div className={style.title}> {archiveTranslations.error}</div>
                <div className={style.subTitle}>
                  {archiveTranslations.errorMessage}
                  <br />
                  <br />
                  boh@mail.it
                </div>
                <div className={style.disclaimer}>
                  {archiveTranslations.closeMessage}
                </div>
                <div className={style.cancel}
                  id="cancelArchive"
                  onClick={(e) => closeArchiveModal(e)}>
                  {archiveTranslations.close}
                </div>
              </div>
            </div>
          }
        </div>
      </Modal>
    )
  }

  return (
    <div className={style.patientMenu}>
      <div className={`${style.dotsContainer} ${showMenu && style.dotsClicked}`}
        onClick={(e) => { handleClick(e) }}>
        <strong>•••</strong>
      </div>
      {showMenu &&
        <div ref={ref} className={dropDownClass()}>
          {activeActions.find(a => a == "edit_sessions") &&
            dropDownItem(actionsUrls.assignRentals, translations.assignSessions, rentals)
          }
          {activeActions.find(a => a == "show_sessions") &&
            dropDownItem(actionsUrls.showRentals, translations.showSessions, rentals)
          }
          {(activeActions.find(a => a == "edit_sessions") ||
            activeActions.find(a => a == "show_sessions")) &&
            !isDemo &&
            <div className={`${style.separator}`}>
            </div>
          }
          {!isDemo && activeActions.find(a => a == "edit_objective") &&
            dropDownItem(actionsUrls.editObjective, translations.editObjective, objective)
          }
          {!isDemo && dropDownItem(actionsUrls.editProfile, translations.editProfile, pencil)}

          {activeActions.includes('assign_patient') &&
            <div>
              <div className={`${style.dropDownAssignItem}`}
                id="confirmAssign">
                <div className={style.iconContainer}>
                  <img src={assign} className={style.icon} />
                </div>
                <div style={{ paddingTop: "5px", paddingBottom: "5px" }}>
                  {translations.assignPatient}
                </div>
              </div>
              {renderAssignModal()}
            </div>
          }

          {!isDemo &&
            <div>
              <div className={`${style.dropDownDeleteItem}`}
                id="confirmDelete">
                <div className={style.iconContainer}>
                  <img src={trash} className={style.icon} />
                </div>
                <div style={{ paddingTop: "5px", paddingBottom: "5px" }}>
                  {translations.deleteUser}
                </div>
              </div>
              {renderArchiveModal()}
            </div>
          }
        </div>
      }
    </div>
  )
}

// solution got from here: https://stackoverflow.com/a/68018429/1897170
function useOnClickOutside(ref, handler) {
  useEffect(
    () => {
      const listener = (event) => {
        // Do nothing if clicking ref's element or descendent elements
        if (!ref.current || ref.current.contains(event.target)) {
          return;
        }
        handler(event);
      };
      document.addEventListener("mousedown", listener);
      document.addEventListener("touchstart", listener);
      return () => {
        document.removeEventListener("mousedown", listener);
        document.removeEventListener("touchstart", listener);
      };
    },
    [ref, handler]
  );
}

PatientMenu.propTypes = {
};

export default PatientMenu;
