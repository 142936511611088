import React from "react";
import style from "./VrtSession.module.sass";

const RecapSession = ({ sceneryParams }) => {
    console.log("sceneryParams:", sceneryParams);
  return (
    <div className={style.recapSession}>
      {Object.keys(sceneryParams).map((key) => {
        const value = sceneryParams[key];
        if (value) {
          return (
            <div key={key}>
              <strong>{key}:</strong> {value}
            </div>
          );
        }
        return null;
      })}
    </div>
  );
};

export default RecapSession;
